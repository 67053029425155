<div class="grid">
  @if (isLoading()) {
    @for (num of skeletonArray; track num) {
      <div class="col-6 answer">
        <p-skeleton width="100%" height="3rem" />
      </div>
    }
  } @else {
    <div
      *ngFor="let choice of questionAnswers(); let answerIndex = index"
      class="col-6 answer"
    >
      <p-button
        [disabled]="isButtonDisabled()"
        [label]="choice.description ?? ''"
        (click)="
          toggleAnswerSelection()(answerSelection(), answerIndex, choice.id)
        "
        [styleClass]="
          'w-full p-3 justify-content-center gap-4 text-xl border-round-sm ' +
          getSelectedAnswerStyle(answerIndex)
        "
      >
        <div class="text-2xl">{{ numberToString(answerIndex + startIndexAnswer()) }}</div>
      </p-button>
    </div>
  }
</div>
