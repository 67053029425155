import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { EuropeanFlagComponent } from '@app/components/common/european-flag/european-flag.component';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { MediaService } from '@app/services/media.service';
import {
  getImageSize,
  IMAGE_RATIO,
  ImageDimensionStyle,
} from '@app/utils/image';
import { ImageModule } from 'primeng/image';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-question-preview-image',
  standalone: true,
  imports: [CommonModule, EuropeanFlagComponent, ImageModule, SkeletonModule],
  templateUrl: './question-preview-image.component.html',
  styleUrl: './question-preview-image.component.scss',
})
export class QuestionPreviewImageComponent {
  imageContainerHeight = input<string>('25vh');
  question = input<QuestionDetail | null>();

  isPictureLoaded = output<boolean>();

  currentImageUri = signal<SafeUrl | string | undefined>(undefined);

  isPictureReadyComputed = computed(() => this.isPictureReady());

  imageRatio = IMAGE_RATIO;

  constructor(public mediaService: MediaService) {
    effect(
      () => {
        const picture = this.question()?.picture;

        if (picture?.base64_file !== undefined) {
          this.currentImageUri.set(
            `data:image/jpg;base64,${picture.base64_file}`,
          );
        } else if (
          picture?.id !== undefined &&
          picture?.keyName !== undefined
        ) {
          this.mediaService
            .getImage(picture.keyName)
            .subscribe((currentImageUri: SafeUrl | undefined) => {
              this.currentImageUri.set(currentImageUri ?? undefined);
            });
        } else {
          this.currentImageUri.set(undefined);
        }
      },
      { allowSignalWrites: true },
    );
  }

  get displayMetropolitanIcon(): boolean {
    const isMetropolitan = this.question()?.hasMetropolitanQuestions;

    return isMetropolitan === true;
  }

  get imageSize(): ImageDimensionStyle {
    const primeNgImageComponent = document.getElementById('question-image');
    if (!primeNgImageComponent) return {};

    const image = primeNgImageComponent.getElementsByTagName('img')[0];
    if (image === undefined) return {};

    const height = image.naturalHeight;
    const width = image.naturalWidth;

    return getImageSize({ height, width });
  }

  isPictureReady(): boolean {
    if (!this.question()) return false;

    const isPictureExist =
      this.question()?.picture?.keyName !== undefined ||
      this.question()?.picture?.base64_file !== undefined;
    const isPictureLoaded =
      isPictureExist && this.currentImageUri() !== undefined;

    if (isPictureLoaded || !isPictureExist) {
      this.isPictureLoaded.emit(true);
    }
    return isPictureLoaded;
  }
}
