import { Component } from '@angular/core';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-european-flag',
  standalone: true,
  imports: [ImageModule],
  styleUrl: './european-flag.component.scss',
  templateUrl: './european-flag.component.html',
})
export class EuropeanFlagComponent {
  europeanFlagImage = '../../../../assets/images/europeanFlag.png';

  constructor() {}
}
