export const generateDecreasingPercentages = (
  total: number,
  segments = 2,
  initialPercent?: number,
): number[] => {
  if (segments < 1) return [];
  if (segments == 1) return [0];

  const percentages: number[] = [];

  // S il n'y a pas de pourcentage initial
  let initPercent = initialPercent;
  if (initPercent === undefined) {
    initPercent = total / 2;
  }

  // Calculer le décrément automatiquement
  const decrement = initPercent / (segments - 1);

  let remainingTotal = total;
  let currentPercent = initPercent;

  for (let i = 0; i < segments; i++) {
    // Assurer que le pourcentage ne dépasse pas le total restant
    if (currentPercent > remainingTotal) {
      currentPercent = remainingTotal;
    }
    if (i === segments - 1) {
      currentPercent = 0;
      percentages.push(currentPercent);
      return percentages;
    }
    percentages.push(currentPercent);
    remainingTotal -= currentPercent;
    currentPercent -= decrement;

    // Assurer que le pourcentage ne tombe pas en dessous de zéro
    if (currentPercent < 0) {
      currentPercent = 0;
    }
  }
  return percentages;
};
