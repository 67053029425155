import {
  DEFAULT_DURATION,
  DEFAULT_DURATION_MS,
  DEFAULT_LOW_DURATION,
} from './constants';

export const getDefaultLowDuration = (): number =>
  DEFAULT_LOW_DURATION * DEFAULT_DURATION_MS;

export const getDurationInSeconds = (duration: number | undefined): number => {
  const isDuration = duration != null && duration > 0;
  return isDuration ? duration : DEFAULT_DURATION;
};

export const getDurationInMs = (duration: number): number => {
  return duration * DEFAULT_DURATION_MS;
};

export const getAdditionalTime = (duration: number): number => {
  return Math.round(duration * (1 + 1 / 3));
};
