import { CommonModule } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AnswerChoices } from '@app/models/question/answerChoices.model';
import { MediaService } from '@app/services/media.service';
import { numberToString } from '@app/utils/stringHelper';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-question-preview-answers',
  standalone: true,
  imports: [ButtonModule, CommonModule, HttpClientModule, SkeletonModule],
  templateUrl: './question-preview-answers.component.html',
})
export class QuestionPreviewAnswersComponent {
  isButtonDisabled = input<boolean>(true);
  isExamSession = input<boolean>(false);
  startIndexAnswer = input<number>(0);
  answerSelection = input<{ [key: number]: string }>({});
  questionAnswers = input<AnswerChoices[] | undefined>([]);

  toggleAnswerSelection = input<
    (
      answerSelection: Record<number, string>,
      answerIndex: number,
      answerId: string,
    ) => void
  >(() => {
    return;
  });

  isQuestionAnswersLoaded = signal(false);

  isLoading = computed(() => !this.isLoadingQuestion());

  numberToString = numberToString;
  skeletonArray = Array(2).fill(2);

  isLoadingQuestion(): boolean {
    const questionAnswers = this.questionAnswers();
    return !!questionAnswers?.length;
  }

  constructor(public mediaService: MediaService) {}

  getSelectedAnswerStyle(answerIndex: number): string | undefined {
    if (this.isButtonDisabled()) {
      const questionAnswers = this.questionAnswers();
      if (questionAnswers == null || questionAnswers.length <= 0) return;
      return (questionAnswers[answerIndex]?.correct ?? false)
        ? 'p-button-primary'
        : 'p-button-tertiary not-selected';
    }

    const myAnswers = this.answerSelection();
    const isSelected = myAnswers?.[answerIndex] !== undefined;
    return isSelected ? 'p-button-primary' : 'p-button-tertiary not-selected';
  }
}
