import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  untracked,
} from '@angular/core';
import {
  checkMissingSound,
  QuestionDetail,
} from '@app/models/question/question-detail.model';

import { UserExamSession } from '@app/models/exam/userExamSession.model';
import { AnswerChoices } from '@app/models/question/answerChoices.model';
import { AuthService } from '@app/services/auth.service';
import { ExamSessionService } from '@app/services/examSession.service';
import { MediaService } from '@app/services/media.service';
import { QuestionService } from '@app/services/question.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { correctQuestion } from '@app/utils/questions/correction';
import {
  getAdditionalTime,
  getDefaultLowDuration,
  getDurationInMs,
  getDurationInSeconds,
} from '@app/utils/timer';
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData,
} from '@components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import { generateDecreasingPercentages } from '@utils/circleCountdownHelper';
import { DEFAULT_QUESTION_PREVIEW_INDEX } from '@utils/constants';
import { AlertSeverity, PfAlertComponent, PfLicenceBarComponent } from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { EMPTY, forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { QuestionCorrectionAnswersComponent } from '../question-correction-answers/question-correction-answers.component';
import { QuestionPreviewAnswersComponent } from '../question-preview-answers/question-preview-answers.component';
import { QuestionPreviewImageComponent } from './question-preview-image/question-preview-image.component';

@Component({
  selector: 'app-question-preview',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    SkeletonModule,
    PfLicenceBarComponent,
    QuestionPreviewAnswersComponent,
    QuestionPreviewImageComponent,
    QuestionCorrectionAnswersComponent,
    PfAlertComponent,
  ],
  templateUrl: './question-preview.component.html',
  styleUrl: './question-preview.component.scss',
})
export class QuestionPreviewComponent implements OnDestroy, OnInit {
  imageContainerHeight = input<string>('25vh');
  isSoundDisabled = input<boolean>(false);
  isTrainingCorrection = input<boolean>(false);
  isExamSession = input<boolean>(false);
  isButtonDisabled = input<boolean>(true);
  currentQuestionIndex = input<number>(DEFAULT_QUESTION_PREVIEW_INDEX);
  question = input<QuestionDetail | null>();
  questionTotal = input<number>();

  goToNextQuestion = output();

  answersSelected = signal<{ [key: number]: string }>({});
  secondAnswersSelected = signal<{ [key: number]: string }>({});
  isSoundLoading = signal<boolean>(false);
  isPictureLoading = signal<boolean>(true);
  isSoundReplayed = signal<boolean>(false);
  isSoundMissing = signal<boolean>(false);
  isTimerStarted = signal<boolean>(false);

  examSession = this.examSessionService.signalExamSession;
  userExamSession = this.userExamSessionService.signalUserExamSession;
  staticVoiceSound = this.mediaService.signalStaticVoices;

  trainingAnswers = computed(() =>
    this.userExamSessionService.signalTrainingAnswers()?.map((ac) => ac?.id),
  );
  computedQuestion = computed(() => this.question());
  countdownColorTime = computed(() =>
    generateDecreasingPercentages(
      this.question()?.duration ?? 30_000 / 1000,
      4,
    ),
  );
  computedStartedCountdown = computed(
    () =>
      this.isTimerStarted() &&
      this.question() !== null &&
      !this.isPictureLoading(),
  );

  answerChoices?: AnswerChoices[];
  secondAnswerChoices?: AnswerChoices[];
  providerId?: string;
  audio?: HTMLAudioElement;
  currentAudioIndex = 0;
  mediaUrls: (string | undefined)[] = [];

  isQuestionCorrect: boolean = true;

  constructor(
    private readonly authService: AuthService,
    private readonly examSessionService: ExamSessionService,
    public mediaService: MediaService,
    private readonly userExamSessionService: UserExamSessionService,
    private readonly dialog: DialogService,
    private readonly questionService: QuestionService,
  ) {
    if (this.authService.checkCandidateToken()) {
      this.providerId =
        this.authService.getProviderIdAndSessionCode().providerId;
    }

    effect(() => {
      const question = this.question();
      const answerIds = this.trainingAnswers();

      if (
        this.isPictureLoading() ||
        this.isSoundLoading() ||
        question == null ||
        !this.isTrainingCorrection()
      )
        return;

      this.isQuestionCorrect = correctQuestion(answerIds, question);
    });

    effect(
      () => {
        const question = this.question();
        const voices = this.staticVoiceSound();
        const isPictureLoading = this.isPictureLoading();

        if (question == null || !voices.length || isPictureLoading) return;

        if (this.isExamSession()) {
          this.answerChoices = question?.answerChoices;
          this.secondAnswerChoices = question?.secondAnswerChoices;

          this.playSound(question);
          return;
        }

        const answerChoices = question?.answerChoices;
        const secondAnswerChoices = question?.secondAnswerChoices;

        const isAnswerChoices =
          answerChoices != null && answerChoices.length > 0;

        const isSecondAnswerChoices =
          secondAnswerChoices != null && secondAnswerChoices.length > 0;

        of([])
          .pipe(
            switchMap(() => {
              if (isAnswerChoices && isSecondAnswerChoices) {
                return this.questionService.getRandomChoicesByAnswers(
                  answerChoices,
                  2,
                );
              } else if (isAnswerChoices) {
                return this.questionService.getRandomChoicesByAnswers(
                  answerChoices,
                  4,
                );
              }
              return of([]);
            }),
            tap((answers) => (this.answerChoices = answers)),
            switchMap(() => {
              if (isSecondAnswerChoices) {
                return this.questionService.getRandomChoicesByAnswers(
                  secondAnswerChoices,
                  2,
                );
              }
              return of([]);
            }),
            tap((answers) => (this.secondAnswerChoices = answers)),
          )
          .subscribe(() => {
            this.playSound(question);
          });
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    if (
      this.staticVoiceSound() == null ||
      this.staticVoiceSound().length === 0
    ) {
      this.mediaService
        .fetchStaticVoices()
        .pipe(tap(() => this.isSoundLoading.set(true)))
        .subscribe(() => this.isSoundLoading.set(false));
    }
  }

  /** SOUND */
  addSoundToMediaUrls(
    voiceSoundId: string | undefined | null,
  ): Observable<string> {
    if (voiceSoundId == null) {
      return EMPTY;
    }
    return this.mediaService.getAudio(voiceSoundId);
  }

  // be careful with signals, if this method is
  // used in an effect, the signal will also respond here
  // we need to omit currentQuestionIndex changes there
  // to preserve precharging and good unfolding of the correction
  setMediaUrls(question: QuestionDetail): Observable<string>[] {
    const audioObservables$: Observable<string>[] = [];

    this.mediaUrls = [];

    const currentQuestionIndex = untracked(() => this.currentQuestionIndex());

    if (this.isTrainingCorrection()) {
      return this.setCorrectionMediaUrls(currentQuestionIndex, question);
    }

    let atLeastOneSoundMissing =
      checkMissingSound(
        question,
        this.answerChoices,
        this.secondAnswerChoices,
      ) || this.staticVoiceSound().length <= 0;

    if (atLeastOneSoundMissing) {
      return [];
    }

    audioObservables$.push(this.addSoundToMediaUrls(question.voiceSound?.id));

    this.answerChoices?.forEach((answer, index) => {
      const voiceSound = this.staticVoiceSound()[index];
      atLeastOneSoundMissing = atLeastOneSoundMissing || voiceSound == null;
      audioObservables$.push(this.addSoundToMediaUrls(voiceSound?.id));
      audioObservables$.push(this.addSoundToMediaUrls(answer.voiceSound?.id));
    });

    if (question.secondVoiceSound != null) {
      audioObservables$.push(
        this.addSoundToMediaUrls(question.secondVoiceSound.id),
      );

      this.secondAnswerChoices?.forEach((answer, index) => {
        const voiceSound = this.staticVoiceSound()[index + 2];
        atLeastOneSoundMissing = atLeastOneSoundMissing || voiceSound == null;
        audioObservables$.push(this.addSoundToMediaUrls(voiceSound.id));
        audioObservables$.push(this.addSoundToMediaUrls(answer.voiceSound?.id));
      });
    }

    if (atLeastOneSoundMissing) {
      return [];
    }

    return audioObservables$;
  }

  setCorrectionMediaUrls(
    currentQuestionIndex: number,
    question: QuestionDetail,
  ): Observable<string>[] {
    const audioObservables$: Observable<string>[] = [];
    const sound =
      this.mediaService.getCorrectionIntroStaticVoices(currentQuestionIndex);

    if (sound == null) {
      console.error('No sound found for question number', currentQuestionIndex);
    } else {
      audioObservables$.push(this.addSoundToMediaUrls(sound.id));
    }

    if (question.correctionVoiceSound?.id == null) {
      return [];
    }

    audioObservables$.push(
      this.addSoundToMediaUrls(question.correctionVoiceSound.id),
    );

    return audioObservables$;
  }

  playAudio(index: number): void {
    if (this.audio) {
      // On pause volontairement l'audio en cours
      this.audio?.pause();
      this.audio.removeEventListener('ended', this.onAudioEnded);
      this.audio.remove();
    }

    this.currentAudioIndex = index;
    this.audio = new Audio(this.mediaUrls[this.currentAudioIndex]);
    this.audio.muted = false;
    this.audio.addEventListener('ended', this.onAudioEnded.bind(this));
    this.audio.play().catch((error) => {
      console.error('Error playing the sound:', error);
      // SI l'erreur correspond au code 20 (PAUSE volontaire de l'audio), on n'ouvre pas la modale car l'erreur a été provoquée pour pallier à un double rendu non souhaité du composant
      // SI l'erreur correspond au code 9 (coupure internet) on ne veut pas non plus afficher la modale de reprise
      if (![20, 9].includes(error.code)) {
        this.openConfirmReconnection();
      }
    });
  }

  onAudioEnded(): void {
    const newIndex = this.currentAudioIndex + 1;
    if (newIndex >= this.mediaUrls.length) {
      // En mode replay on ne déclenche pas le timer à la fin de l'audio
      if (!this.isSoundReplayed()) {
        this.isTimerStarted.set(true);
      }
      if (this.audio === undefined) return;
      //We need to remove the last event listener
      this.audio.removeEventListener('ended', this.onAudioEnded);
      this.audio.remove();
      // On libère la référence en mémoire
      this.audio = undefined;
      return;
    }
    this.playAudio(newIndex);
  }

  playSound(question?: QuestionDetail | null): void {
    const isSoundDisabled = this.isSoundDisabled();
    if (question == null || isSoundDisabled) return;

    const mediaUrls$ = this.setMediaUrls(question);

    if (!mediaUrls$.length) {
      this.startTimerWithMissingSound();
    }

    forkJoin(mediaUrls$).subscribe(([...urls]) => {
      this.mediaUrls = [...urls];
      this.playAudio(0);
    });
  }

  startTimerWithMissingSound(): void {
    console.info('No voice sound id, start timer');
    this.isTimerStarted.set(true);
    this.isSoundMissing.set(true);
  }

  replaySound(): void {
    // On initialise le Mode replay (flag pour disabled le button de soundReplay et ne pas redéclencher startTimer dans onAudioEnded())
    this.isSoundReplayed.set(true);
    // On rejoue le son apres s'être assure que la question est tjrs disponible
    const question = this.question();
    if (question == null) return;
    this.playAudio(0);
  }

  /** QUESTIONS / ANSWERS */
  get questionsTotal(): number {
    return this.questionTotal() ?? 0;
  }

  toggleAnswerSelection(
    answers: Record<number, string>,
    answerIndex: number,
    answerId: string,
  ): { [key: number]: string } {
    const isAnswerSelected = answers[answerIndex] === answerId;

    if (isAnswerSelected) {
      delete answers[answerIndex];
    } else {
      answers[answerIndex] = answerId;
    }

    return answers;
  }

  isSecondQuestionAnswers(): boolean {
    return (
      this.secondAnswerChoices != null && this.secondAnswerChoices.length > 0
    );
  }

  get firstSelectedAnswers(): string[] {
    return Object.values(this.answersSelected());
  }

  get secondSelectedAnswers(): string[] {
    return Object.values(this.secondAnswersSelected());
  }

  saveAnswers(): Observable<UserExamSession | undefined> {
    const selectedAnswerIds = [
      ...this.firstSelectedAnswers,
      ...this.secondSelectedAnswers,
    ];

    const examSessionId = this.examSession()?.id;
    const currentQuestion = this.question();

    if (examSessionId == null || this.providerId == null || !currentQuestion)
      return of(undefined);

    return this.userExamSessionService
      .saveAnswers(
        examSessionId,
        this.providerId,
        currentQuestion.id,
        selectedAnswerIds,
      )
      .pipe(
        switchMap(() => {
          if (this.providerId != null) {
            return this.userExamSessionService.updateLastSavedQuestionId(
              examSessionId,
              this.providerId,
              currentQuestion.id,
            );
          }
          return of(undefined);
        }),
      );
  }

  get severity(): AlertSeverity {
    return this.isQuestionCorrect ? 'success' : 'error';
  }

  get correctionTitle(): string {
    return this.isQuestionCorrect ? 'Réponse correcte' : 'Réponse fausse';
  }

  isPictureUrlLoaded($event: boolean): void {
    this.isPictureLoading.set(!$event);
  }

  removeCurrentAudioInfo(): void {
    this.audio?.pause();
    this.audio?.removeEventListener('ended', this.onAudioEnded);
    this.audio?.remove();
    this.audio = undefined;
    this.currentAudioIndex = 0;
    this.mediaUrls.forEach((url) => URL.revokeObjectURL(url ?? ''));
    this.isSoundReplayed.set(false);
  }

  /** onCountDownCompleted event */
  onTimeOver(): void {
    this.isTimerStarted.set(false);
    this.removeCurrentAudioInfo();

    if (this.question() == null) return;

    let obs: Observable<UserExamSession | undefined> = of(undefined);

    if (!this.isTrainingCorrection()) {
      // we don't need to save the answers if we are in correction mode
      obs = obs.pipe(switchMap(() => this.saveAnswers()));
    }

    obs.subscribe(() => {
      // reset answer choices
      this.answersSelected.set({});
      this.secondAnswersSelected.set({});

      this.goToNextQuestion.emit();
    });
  }

  openConfirmReconnection(): void {
    const dialog = this.dialog.open(SpecificConfirmDialogComponent, {
      data: {
        labelHtml:
          "Votre session a rencontré un problème de connexion, cliquez sur le bouton ci-dessous pour reprendre l'examen",
        title: "Reprendre l'examen",
        confirmLabel: 'Confirmer',
        cancelLabel: 'Annuler',
        displayCancelButton: false,
      } satisfies SpecificConfirmDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    dialog?.onClose?.subscribe(() => {
      this.playAudio(0);
    });
  }

  // FIXME : Computed signal?
  getDuration(): number {
    if (this.isTrainingCorrection()) return getDefaultLowDuration();
    const userExamSession = this.userExamSession();
    const questionDuration = this.question()?.duration;

    let duration = getDurationInSeconds(questionDuration);

    if (userExamSession?.additionalTime) {
      duration = getAdditionalTime(duration);
    }

    return getDurationInMs(duration);
  }

  ngOnDestroy(): void {
    this.removeCurrentAudioInfo();
  }
}
