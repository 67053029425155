<div [class]="!isPictureReadyComputed() ? '' : 'image-section'">
  <div
    class="image-container relative"
    [class]="!isPictureReadyComputed() ? 'pt-2' : ''"
    [ngStyle]="{
      'aspect-ratio': imageRatio,
      height: imageContainerHeight(),
    }"
  >
    @if (isPictureReadyComputed()) {
      <p-image
        [src]="currentImageUri()"
        alt="image {{ question()?.picture?.name }}"
        [ngClass]="{ 'p-image': imageSize }"
        id="question-image"
      >
      </p-image>
    }
    <div *ngIf="displayMetropolitanIcon" class="absolute european-flag">
      <app-european-flag></app-european-flag>
    </div>
    @if (!isPictureReadyComputed()) {
      <p-skeleton
        [animation]="!question()?.picture ? 'none' : 'circle'"
        width="100%"
        height="100%"
      ></p-skeleton>
    }
  </div>
</div>
