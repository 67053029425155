export const IMAGE_RATIO = 1.67; // width/height

export type ImageDimensionStyle =
  | { height: '100%' }
  | { width: '100%' }
  | Record<string, never>;

export const getImageSize = ({
  height,
  width,
}: {
  height: number;
  width: number;
}): ImageDimensionStyle => {
  const ratio = width / height;

  if (ratio > IMAGE_RATIO) {
    return { height: '100%' };
  } else {
    return { width: '100%' };
  }
};
