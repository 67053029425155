@if (isExamSession() && questionTotal() !== undefined) {
  <pf-licence-bar
    [current]="currentQuestionIndex()"
    [total]="questionsTotal"
    [candidateName]="providerId ?? '-'"
    [countdownDuration]="getDuration()"
    [countdownColors]="['#073ca5', '#5986e0', '#f78c01', '#cc0000']"
    [countdownColorTime]="countdownColorTime()"
    [start]="computedStartedCountdown()"
    (onCountDownCompleted)="onTimeOver()"
    [displaySound]="false"
    class="col-6"
  >
    @if (
      isTimerStarted() && !this.isSoundReplayed() && !this.isSoundMissing()
    ) {
      <ng-template #additionalContent>
        <i
          role="button"
          tabindex="0"
          class="pi pi-replay sound-replay-icon"
          (keyup)="replaySound()"
          (click)="replaySound()"
        ></i>
      </ng-template>
    } @else {
      <ng-template #additionalContent>
        <i class="pi pi-replay sound-replay-icon-disabled"></i>
      </ng-template>
    }
  </pf-licence-bar>
}

@if (!isExamSession() && isSoundMissing()) {
  <pf-alert
    label="Attention, la question n'a pas de son. Veuillez générer un son avant de valider la question."
    severity="warn"
  ></pf-alert>
}
<app-question-preview-image
  [imageContainerHeight]="imageContainerHeight()"
  (isPictureLoaded)="isPictureUrlLoaded($event)"
  [question]="computedQuestion()"
/>

<div class="question-container text-xl">
  <p class="description">
    {{ question()?.description }}
  </p>
  <app-question-preview-answers
    *ngIf="!isTrainingCorrection()"
    [answerSelection]="answersSelected()"
    [questionAnswers]="answerChoices"
    [isExamSession]="isExamSession()"
    [isButtonDisabled]="isButtonDisabled()"
    [toggleAnswerSelection]="toggleAnswerSelection"
  >
  </app-question-preview-answers>
  <app-question-correction-answers
    *ngIf="isTrainingCorrection() && question() !== null"
    [questionAnswers]="answerChoices"
  >
  </app-question-correction-answers>

  @if (isSecondQuestionAnswers()) {
    <p class="description">
      {{ question()?.secondDescription }}
    </p>

    <app-question-preview-answers
      *ngIf="!isTrainingCorrection()"
      [answerSelection]="secondAnswersSelected()"
      [questionAnswers]="secondAnswerChoices"
      [isExamSession]="isExamSession()"
      [startIndexAnswer]="2"
      [isButtonDisabled]="isButtonDisabled()"
      [toggleAnswerSelection]="toggleAnswerSelection"
    >
    </app-question-preview-answers>
    <app-question-correction-answers
      *ngIf="isTrainingCorrection() && question() !== null"
      [questionAnswers]="secondAnswerChoices"
      [startIndexAnswer]="2"
    >
    </app-question-correction-answers>
  }

  <pf-alert
    *ngIf="isTrainingCorrection() && question() !== null"
    [label]="question()?.correction ?? ''"
    [severity]="severity"
    [title]="correctionTitle"
  ></pf-alert>
</div>
