import { QuestionDetail } from '@app/models/question/question-detail.model';

export const correctQuestion = (
  candidateAnswerIds: string[] | null,
  question: QuestionDetail,
): boolean => {
  const questionAnswers = [
    ...(question?.answerChoices ?? []),
    ...(question?.secondAnswerChoices ?? []),
  ];

  if (candidateAnswerIds == null || candidateAnswerIds.length === 0) {
    return false;
  }

  let isQuestionCorrect = true;

  questionAnswers.forEach(({ id, correct }) => {
    const isSelectedByCandidate = candidateAnswerIds.includes(id);

    if (correct === true && !isSelectedByCandidate) {
      isQuestionCorrect = false;
    }

    if (correct === false && isSelectedByCandidate) {
      isQuestionCorrect = false;
    }
  });

  return isQuestionCorrect;
};
